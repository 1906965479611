import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  paper: {
    display: 'flex',
    backgroundColor: '#fff',
    minHeight: '100vh',
  },
  modalContent: {
    position: 'relative',
    color: '#FFF',
    width: '90%',
    minHeight: '100%',
    padding: '100px 0 30px',
    marginLeft: 'auto',
    marginRight: 'auto',
    '&>div': {
      margin: 'auto',
      height: '100%',
      '&>div': {
        height: '100%',
        paddingBottom: theme.spacing(4),
        maxWidth: 1460,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    '& h4': {
      fontWeight: 600,
      color: '#272727',
      marginBottom: theme.spacing(5),
      '& span': {
        color: '#D34246',
        fontWeight: '700 !important',
      },
    },
  },
  buttonClose: {
    position: 'absolute',
    top: 15,
    right: 0,
    color: 'inherit',
  },
  flexBox: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '@media (max-width: 768px)': {
      flexWrap: 'wrap',
    },
    '& .MuiTextField-root': {
      '& .MuiFilledInput-root': {
        '&.Mui-focused': {
          backgroundColor: '#ffffff !important',
        },
      },
    },

    '& div': {
      backgroundColor: 'transparent !important',
      padding: 0,
    },
    '&>*': {
      flex: '1 1 50%',
      marginBottom: theme.spacing(3),
      '@media (max-width: 768px)': {
        flex: '1 1 100%',
      },
    },
    '*&>*:not(:last-child)': {
      marginRight: theme.spacing(3),
      '@media (max-width: 768px)': {
        marginRight: 0,
      },
    },

    '& input, & textarea': {
      color: '#272727',
      fontSize: '0.825rem',
      border: '1px solid #808080',
      borderRadius: 4,
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 12,
      paddingRight: 12,
    },
    '& input': {},
    '& fieldset': {
      color: '#272727',
      borderColor: '#808080 !important',
      '& legend': {
        color: '#272727',
      },
    },
  },
  menuBottom: {
    marginTop: 80,
    color: '#272727',

    '@media (max-width: 1200px)': {
      display: 'none',
    },
    '& a': {
      color: '#272727',
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
    },
  },
  telephoneFr: {
    marginBottom: theme.spacing(4),
  },
  textSocialMedia: {
    marginBottom: theme.spacing(2),
    color: '#272727',
  },
  btnSubmit: {
    color: '#fff',
    backgroundColor: '#D34246',
    width: '100%',
    letterSpacing: 1.25,
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: theme.spacing(3),
    '@media(max-width: 992px)': {
      width: 'auto',
      paddingLeft: 15,
      paddingRight: 15,
    },
    '&:hover, &:focus': {
      backgroundColor: '#D34246',
      opacity: '0.75',
    },
  },
  siteBrand: {
    flex: 1,
    maxWidth: 703,
    '@media (max-width: 1400px)': {
      maxWidth: 500,
    },
    '@media (max-width: 1200px)': {
      display: 'none',
    },
    '& img': {
      width: '100%',
    },
  },
  formWrapper: {
    width: 577,
    '@media (max-width: 1400px)': {
      width: '100%',
    },
  },
  formControlLabel: {
    color: '#272727',
    alignItems: 'flex-start',
    display: 'flex',
    '& .MuiCheckbox-root': {
      paddingTop: 0,
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '0.75rem',
    },
    '& svg': {
      color: '#808080',
    },
  },
  contentFlexLink:{
    '& a':{
      display:'flex',
      alignItems: 'center'
    }
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
}));

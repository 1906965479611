import React, { ChangeEvent, useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Paper,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import styles from './style';
import IconClose from '@material-ui/icons/Close';
import { TransitionLink } from '../../Common/TransitionLink/TransitionLink';
import { TransitionLink as Link } from '../../Common/TransitionLink/TransitionLink';
import IconPhone from '../../Common/Icons/IconPhone';
import IconMail from '../../Common/Icons/IconMail';
import SocialNetwork from '../../Common/SocialNetwork';
import imgContact from '../../../images/element_contact.svg';
import isEmail from 'validator/es/lib/isEmail';
import { sendMailContact } from '../../../api/sendMail';
import SnackBar from '@material-ui/core/Snackbar';
import { checkPhoneNumber } from '../../../Services/validation';

interface StateInterface {
  tel: string;
  mail: string;
  subject: string;
  message: string;
  name: string;
  isAccept: boolean;
}

function Contact(props: any) {
  const classes = styles();
  const { prevUrl } = props;

  const [input, setInput] = useState<StateInterface>({
    tel: '',
    mail: '',
    subject: '',
    message: '',
    name: '',
    isAccept: false,
  });

  const [snackBar, setSnackBar] = useState(false);
  const [messageSnackBar, setMessageSnackBar] = useState('');

  const handleClose = () => {
    setSnackBar(false);
  };

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, [event.target.name]: event.target.value });
  };

  const handleChangeCheckBox = (event: ChangeEvent<{}>, isAccept: boolean) => {
    setInput({ ...input, isAccept });
  };

  const handleSubmit = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isEmail(input.mail)) {
      setMessageSnackBar("L'email n'est pas valide.");
      setSnackBar(true);
      return;
    }
    if (!checkPhoneNumber(input.tel)) {
      setMessageSnackBar("Le numéro de téléphone n'est pas valide");
      setSnackBar(true);
      return;
    }
    const { mail, name, subject, tel, message } = input;

    const initState = () => {
      setInput({
        name,
        tel: '',
        mail: '',
        subject: '',
        message: '',
        isAccept: true,
      });
    };

    sendMailContact(
      { mail, name, subject, tel, message },
      setSnackBar,
      setMessageSnackBar,
      initState,
    );
  };

  const url = typeof window !== 'undefined' ? window.location.href : '';
  const domaine_ext = url.includes(".fr");

  return (
    <Paper className={classes.paper}>
      <Box className={classes.modalContent}>
        <TransitionLink
          settings={{
            cover: undefined,
            paintDrip: true,
            hex: '#272727',
          }}
          to={prevUrl ? prevUrl : '/'}
          style={{ color: '#272727' }}
        >
          <IconButton edge="end" className={classes.buttonClose}>
            <IconClose fontSize="large" />
          </IconButton>
        </TransitionLink>
        <Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box display="flex" justifyContent="space-between">
              <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                className={classes.siteBrand}
              >
                <img
                  width={477}
                  src={imgContact}
                  alt="Hairun technology"
                  title="Hairun technology"
                />
              </Box>
              <Box className={classes.formWrapper}>
                <Typography variant="h4" className="titreSection">
                  Contactez-<span>nous</span>
                </Typography>
                <form onSubmit={handleSubmit}>
                  <Box className={classes.flexBox}>
                    <TextField
                      variant="filled"
                      placeholder="Nom"
                      required={true}
                      onChange={handleChangeInput}
                      value={input.name}
                      name="name"
                    />
                  </Box>

                  <Box className={classes.flexBox}>
                    <TextField
                      variant="filled"
                      placeholder="Téléphone *"
                      required={true}
                      onChange={handleChangeInput}
                      value={input.tel}
                      name="tel"
                    />
                  </Box>

                  <Box className={classes.flexBox}>
                    <TextField
                      variant="filled"
                      placeholder="Email *"
                      required={true}
                      onChange={handleChangeInput}
                      value={input.mail}
                      name="mail"
                    />
                  </Box>

                  <Box className={classes.flexBox}>
                    <TextField
                      variant="filled"
                      placeholder="Sujet *"
                      required={true}
                      onChange={handleChangeInput}
                      value={input.subject}
                      name="subject"
                    />
                  </Box>
                  <Box className={classes.flexBox}>
                    <TextField
                      variant="filled"
                      placeholder="Message"
                      multiline
                      rows={6}
                      fullWidth
                      required={true}
                      onChange={handleChangeInput}
                      value={input.message}
                      name="message"
                    />
                  </Box>
                  <Box className={classes.flexBox}>
                    <FormControlLabel
                      className={classes.formControlLabel}
                      control={<Checkbox name="" />}
                      label="En soumettant ce formulaire, j'accepte que les informations saisies soient exploitées dans le cadre de la demande de devis et de la relation commerciale qui peut en découler."
                      value={input.isAccept}
                      onChange={handleChangeCheckBox}
                    />
                  </Box>
                  <Box textAlign="center">
                    <Button
                      color="default"
                      variant="contained"
                      disableElevation
                      className={classes.btnSubmit}
                      type="submit"
                      disabled={!input.isAccept}
                    >
                      Envoyer
                    </Button>
                  </Box>
                </form>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              className={classes.menuBottom}
            >
              <Box>

                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                >
                  <Typography variant="body2">HaiRun Technology</Typography>
                  <Typography variant="body2">Immeuble Sodiama</Typography>
                  <Typography variant="body2">
                    Rocade Marais Masay Ankorondrano - Antananarivo Madagascar
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  className={classes.mt2}
                >
                  <Typography variant="body2">HaiRun Technology France</Typography>
                  <Typography variant="body2">59 rue des Petits Champs 75001 PARIS</Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                className={classes.contentFlexLink}
              >

                <Link
                  href="tel:+261 34 70 482 73"
                  color="inherit"
                  underline="none"
                  variant="body2"
                  gutterBottom
                >
                  <IconPhone
                    viewBox="0 0 18.569 18.568"
                    style={{ marginRight: 12, fontSize: 16 }}
                  />
                  +261 34 70 482 73
                </Link>
                <>
                  <Link
                    href="tel:+33 1 42 44 12 65"
                    color="inherit"
                    underline="none"
                    variant="body2"
                    gutterBottom
                  >
                    <IconPhone
                      viewBox="0 0 18.569 18.568"
                      style={{ marginRight: 12, fontSize: 16, opacity: 0 }}
                    />
                    +33 1 42 44 12 65
                  </Link>
                  <Link
                    href="tel:+33 7 51 43 14 36"
                    color="inherit"
                    underline="none"
                    variant="body2"
                    gutterBottom
                  >
                    <IconPhone
                      viewBox="0 0 18.569 18.568"
                      style={{ marginRight: 12, fontSize: 16, opacity: 0 }}
                    />
                    +33 7 51 43 14 36
                  </Link></>
                <Box className={classes.mt2}>
                  <Link
                    href="mailto:contact@hairun-technology.com"
                    color="inherit"
                    underline="none"
                    variant="body2"
                  >
                    <IconMail
                      viewBox="0 0 20.862 14.725"
                      style={{ marginRight: 12, fontSize: 16 }}
                    />
                    contact@hairun-technology.com
                  </Link>
                  <Link
                    href="mailto:contact@hairun-technology.fr"
                    color="inherit"
                    underline="none"
                    variant="body2"
                  >
                    <IconMail
                      viewBox="0 0 20.862 14.725"
                      style={{ marginRight: 12, fontSize: 16, opacity: 0 }}
                    />
                    contact@hairun-technology.fr
                  </Link>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
              >
                <Typography variant="body2" className={classes.textSocialMedia}>
                  Suivez-nous sur :
                </Typography>
                <SocialNetwork htmlColor="#272727" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <SnackBar
        open={snackBar}
        message={messageSnackBar}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={handleClose}
        action={
          <IconButton onClick={handleClose} size="small" color="inherit">
            <IconClose />
          </IconButton>
        }
      />
    </Paper>
  );
}

export default Contact;

import React from 'react';
import ContactPage from '../components/PagesComponent/Contact/Contact';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import theme from '../Theme/theme';

function Contact(props: any) {
  const { location: locationProps } = props;
  const isBrowser = typeof window !== 'undefined';
  const url: string =
    isBrowser && locationProps ? locationProps.state.prevUrl : '/';
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ContactPage prevUrl={url} />
    </ThemeProvider>
  );
}

export default Contact;
